import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../layouts/Site";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { join } from "../styles/styles";

const toImage = require('save-svg-as-png-3');

const PRINTED_CARD = "printed-card";
const PRINTABLE_AREA = "printable-area";
const DISPLAYED_CARD = "displayed-card";
const CARD_TITLE = "-title";
const CARD_DESCRIPTION = "-description";

// Need to spell out the colours to load them through webpack
const VALUES = {
  "OUTCOMES": {width: 50, bgColor: "bg-primary", fillColor: "fill-primary"},
  "HOLISM": {width: 38, bgColor: "bg-secondary2", fillColor: "fill-secondary2"},
  "COLLABORATION": {width: 71, bgColor: "bg-secondary", fillColor: "fill-secondary"},
  "CANDOUR": {width: 45, bgColor: "bg-complementary", fillColor: "fill-complementary"},
}

function printedCard() { return document.getElementById(PRINTED_CARD); }
function printableArea() { return document.getElementById(PRINTABLE_AREA); }
function cardTitle() { return document.getElementById(DISPLAYED_CARD + CARD_TITLE); }
function cardDescription() { return document.getElementById(DISPLAYED_CARD + CARD_DESCRIPTION); }

export default function CardDesignPage() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [filename, setFilename] = useState("Culture nudge");
  const [referencedValues, setReferencedValues] = useState([]);
  const [outOfBounds, setOutOfBounds] = useState(false);
  const [counter, setCounter] = useState(0);
  const [useCounter, setUseCounter] = useState(false);

  function download() {
    const imageOptions = {
      scale: 4,
      backgroundColor: "white",
      encoderOptions: 1,
    }

    toImage.saveSvgAsPng(printedCard(), appendCounter(filename) + ".png", imageOptions);
    setCounter(counter + 1);
  }

  function appendCounter(text) {
    if (useCounter)
      return text + " " + ("" + counter).padStart(2, "0");
    return text;
  }

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.value = (document.activeElement === inputRef.current ? filename : appendCounter(filename));
  });

  function setValue(value, isEnabled) {
    if (isEnabled) setReferencedValues(Object.keys(VALUES).filter(v => referencedValues.includes(v) || v === value));
    else setReferencedValues(referencedValues.filter(v => v !== value));
  }

  return <Layout title="Culture nudge">
    <div className="mt-32 mx-32 flex flex-row">
      <Card title={title} content={content} outOfBounds={outOfBounds} setOutOfBounds={setOutOfBounds} values={referencedValues}/>
      <div className="flex-grow ml-4">
        <textarea className="w-full my-3 p-2 block bg-gray-200" rows="3" value={title} onChange={e => setTitle(e.target.value)} placeholder="Title"/>
        <textarea className="w-full my-3 p-2 block bg-gray-200" rows="10" value={content} onChange={e => setContent(e.target.value)} placeholder={"Description\n\nTo start a new paragraph, add an empty line"}/>
        <div>
          Referenced values:
          { Object.keys(VALUES).map(v => <label className={join("rounded-full text-page inline-block px-2 mx-1", VALUES[v].bgColor)}><input type="checkbox" onChange={e => setValue(v, e.target.checked)}/> {v}</label>) }
        </div>
        <input ref={inputRef} className="my-3 bg-gray-200 p-2"
               onFocus={e => e.target.value = filename}
               onBlur={e => e.target.value = appendCounter(filename)}
               onChange={e => setFilename(e.target.value)}
        />
        <button onClick={download} disabled={outOfBounds} className="mx-3 rounded-md p-2 bg-primary disabled:opacity-50 text-page"><Icon icon={faDownload} /> .png</button>
        <div>
          <input type="checkbox" checked={useCounter} onChange={() => setUseCounter(!useCounter)}/>
          &nbsp; Append download count
        </div>
      </div>
    </div>
  </Layout>;
}

function Card({title, content, values, outOfBounds, setOutOfBounds}) {
  const width = 263.04001;
  const height = 359.03867;
  const margin = 26;

  const titleSize = 32;
  const contentSize = 15;
  const labelSize = 8;
  const lineHeight = 1.25;

  const textWidth = width - 2 * margin;
  const textHeight = height - 2 * margin - 2*labelSize;

  const titleStyle = {
    fontWeight: "bold",
    fontSize: titleSize + "px",
    lineHeight: "1",
    whiteSpace: "pre"
  };
  const descriptionStyle = {
    fontWeight: "300",
    fontSize: contentSize + "px",
    lineHeight: lineHeight + "",
    whiteSpace: "pre"
  };
  const labelStyle = {
    fontWeight: "500",
    fontSize: labelSize + "px",
    lineHeight: "1",
    whiteSpace: "pre",
    textAlign: "center",
    textAnchor: "middle"
  };

  function CardTemplate() {
    const style = {
      fill: "none",
      stroke: "#21b7eb",
      strokeWidth: "1",
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeMiterlimit: "4",
      strokeDasharray: "none",
      strokeOpacity: "1"
    };

    return <g id="layer:card" transform="matrix(1.3333333,0,0,-1.3333333,0,359.03867)" style={{ display: "inline" }}>
      <g transform="translate(188.6396,20.1602)">
        <path style={style}
              d="m 0,0 c 0,-6.363 -5.156,-11.52 -11.52,-11.52 h -156.96 c -6.362,0 -11.52,5.157 -11.52,11.52 v 228.959 c 0,6.363 5.158,11.52 11.52,11.52 h 156.96 c 6.364,0 11.52,-5.157 11.52,-11.52 z" />
      </g>
    </g>;
  }

  function PrintableBox({ width, height, margin, display = false }) {
    if (display)
      return <rect id={PRINTABLE_AREA} x={margin} y={margin} width={width} height={height} strokeDasharray="4 4" stroke="darkGray" fill="none"/>

    return <clipPath id={PRINTABLE_AREA}>
      <rect x={margin} y={margin} width={width} height={height} />
    </clipPath>;
  }

  function Text({ x = 0, y, children }) {
    return <tspan x={x} y={y}>{children}</tspan>
  }

  function ValueLabels({ values }) {
    const result = [];
    let x = 0;

    values.forEach(v => {
      result.push(<Label x={x} width={VALUES[v].width} color={VALUES[v].fillColor}>{v}</Label>);
      x = x + VALUES[v].width + 2;
    });

    return result;

    function Label({x, width, color, children}) {
      return <g transform={"translate(" + margin + "," + (margin + textHeight + labelSize / 2) +")"}>
        <rect x={x} y="0" width={width} height={labelSize * 1.5} ry={labelSize * 1.5 / 2} className={color} />
        <text className="font-body fill-page" style={labelStyle} >
          <Text x={x + width / 2} y={9}>{children}</Text>
        </text>
      </g>
    }
  }

  function CardContents({ titleLines, contentLines, id, className, children }) {
    return <svg id={id} viewBox={"0 0 " + width + " " + height} height={height} width={width} className={className}>
      {children}
      <g style={{ display: "inline" }}>
        <text id={id + CARD_TITLE} transform={"translate(" + margin + "," + (margin + titleSize) + ")"} style={titleStyle}
              className="font-title">
          {titleLines.flat().map((t, i) => <Text y={i * titleSize}>{t}</Text>)}
        </text>
        <text id={id + CARD_DESCRIPTION} transform={"translate(" + margin + "," + (margin + (titleLines.flat().length + 1) * titleSize) + ")"}
              style={descriptionStyle} className="font-body">
          {contentLines.flat().map((t, i, list) => <Text
            y={i * contentSize * lineHeight - list.slice(0, i).filter(t => t.trim().length === 0).length * (contentSize * lineHeight - contentSize / 2)}>{t}</Text>)}
        </text>
      </g>
    </svg>;
  }

  const [titleLines, setTitleLines] = useState(toLines(title));
  const [contentLines, setContentLines] = useState(toLines(content));
  const [wrappingInProgress, setWrappingInProgress] = useState(false);

  useEffect(() => setTitleLines(toLines(title)), [title]);
  useEffect(() => setContentLines(toLines(content)), [content]);

  useEffect(() => wrap(cardTitle(), textWidth, titleLines, setTitleLines, wrappingInProgress, setWrappingInProgress), [titleLines, textWidth, wrappingInProgress]);
  useEffect(() => wrap(cardDescription(), textWidth, contentLines, setContentLines, wrappingInProgress, setWrappingInProgress), [contentLines, textWidth, wrappingInProgress]);

  useEffect(() => {
    if (wrappingInProgress) return;
    const printableBounds = printableArea().getBoundingClientRect();
    setOutOfBounds(cardDescription().getBoundingClientRect().bottom > printableBounds.bottom || Math.max(cardTitle().getBoundingClientRect().right, cardDescription().getBoundingClientRect().right) > printableBounds.right);
  }, [wrappingInProgress, titleLines, contentLines, setOutOfBounds]);

  return <>
    <CardContents titleLines={titleLines} contentLines={contentLines} id={DISPLAYED_CARD} className={outOfBounds ? "bg-complementary" : ""}>
      <CardTemplate />
      <PrintableBox width={textWidth} height={textHeight} margin={margin} display />
      <ValueLabels values={values} />
    </CardContents>
    <CardContents titleLines={titleLines} contentLines={contentLines} id={PRINTED_CARD} className="hidden" >
      <ValueLabels values={values} />
    </CardContents>
  </>
}

function toLines(text) {
  return text.trim().split(/ *\n */);
}

function wrap(element, width, lines, setLines, wrappingInProgress, setWrappingInProgress) {
  setWrappingInProgress(false);

  function exceedsWidth(element) {
    return element.getBoundingClientRect().width > width;
  }

  function truncateLine(line) {
    const words = line.split(/ +/);
    if (words.length < 2) throw "word too long";
    setWrappingInProgress(true);
    return [words.slice(0, -1).join(" "), words[words.length - 1]];
  }

  function wrapLine(line, tspanElements, tspanIndex) {
    if (exceedsWidth(tspanElements.item(tspanIndex)))
      return truncateLine(line);
    return line;
  }

  function wrapWrappedLine(wrappedLine, tspanElements, tspanIndex) {
    let lines = []
    for (let i = 0; i < wrappedLine.length; ++i) {
      if (exceedsWidth(tspanElements.item(tspanIndex++))) {
        const [truncatedLine, lastWord] = truncateLine(wrappedLine[i]);
        lines.push(truncatedLine);
        if (i < (wrappedLine.length - 1))
          lines.push([lastWord, wrappedLine[i + 1]].join(" "));
        else
          lines.push(lastWord);
        return lines;
      }
      else lines.push(wrappedLine[i]);
    }
    return lines;
  }

  function wrapLines(lines, tspanElements, tspanIndex) {
    let newLines = lines.map(l => {
      if (Array.isArray(l)) {
        const startSpanIndex = tspanIndex;
        tspanIndex += l.length;
        return wrapWrappedLine(l, tspanElements, startSpanIndex);
      }
      return wrapLine(l, tspanElements, tspanIndex++)
    });
    return newLines;
  }

  if (exceedsWidth(element)) {
    try {
      setLines(wrapLines(lines, element.getElementsByTagName("tspan"), 0));
    }
    catch (wordTooLong) {
      // Don't set theLines, so stop rendering
    }
  }
}